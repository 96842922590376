import { graphql, useStaticQuery } from 'gatsby'
import React, { ReactElement } from 'react'

import DestinationsLayout from '../../components/Layout/Destinations'
import Destinations from '../../components/DestinationsPage/Destinations'
import Hero from '../../components/DestinationsPage/Hero'
import NoData from '../../components/NoData'
import SEO from '../../components/SEO/SEO'

const DestinationsPage = (): ReactElement => {
  const featuredDestination = useStaticQuery(graphql`
    query featuredDestination {
      allSitePage(filter: { context: { category: { eq: "destinations" } } }) {
        edges {
          node {
            path
            context {
              data {
                id
                estate
                description
                title
                updated
                feature
                featuredMedia {
                  media
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = featuredDestination.allSitePage.edges
  const featured = data.filter((res: any) => res.node.context.data.feature === true)
  const noData = data.length > 0 ? false : true

  return (
    <DestinationsLayout noData={noData}>
      <SEO title="DESTINATIONS - ALISI | Ayala Land" />
      {!noData ? (
        <>
          <Hero data={featured.length > 0 ? featured[0].node : data[0].node} />
          <Destinations />
        </>
      ) : (
        <NoData withBackground={true} title="destinations" />
      )}
    </DestinationsLayout>
  )
}

export default DestinationsPage
