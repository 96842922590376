import { Link, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'

import './Hero.scss'
import GridLogo from '../../GridLogo/GridLogo'
import { FeaturedData } from '../data'
import DiscoverArrow from '../../../assets/icons/DiscoverArrow'

const Hero = (props: { data: FeaturedData; detail?: boolean; image?: any }): JSX.Element => {
  const [mediaCount, setMediaCount] = useState<number>(0)

  const { data, detail, image } = props
  const { path } = data
  const featuredData = data.context.data
  const media = featuredData.featuredMedia[mediaCount]

  useEffect(() => {
    const timer = setInterval(() => {
      const count = mediaCount + 1

      if (count === featuredData.featuredMedia.length) {
        setMediaCount(0)
      } else {
        setMediaCount(count)
      }
    }, 8000)
    return (): void => clearInterval(timer)
  }, [mediaCount, featuredData])

  return (
    <>
      <div className={`destinations-hero-banner ${detail && 'detail'}`} />

      <div className={`destinations-banner-container ${detail && 'detail'}`}>
        {featuredData && media.mediaType === 'image' ? (
          <div className={`destinations-hero-media ${detail && 'detail'}`}>
            <img className={`bg-pan-right  ${detail && 'detail'}`} src={media.media} />
          </div>
        ) : (
          <video className={`hero-media-video fade-in ${detail && 'detail'}`} autoPlay muted loop>
            <source src={media.media} type="video/mp4" />
          </video>
        )}
      </div>

      <div className={`destinations-hero-mask-2 ${detail && 'detail'}`} />
      <div className={`destinations-hero-mask ${detail && 'detail'}`} />
      <div className={`destinations-hero-grid ${detail && 'detail'}`}>
        <GridLogo />
      </div>
      <div className={`destinations-hero-header ${detail && 'detail'}`}>
        {featuredData.estate ? (
          <div className="destinations-hero-tag-container">
            <div className="destinations-hero-tag">{featuredData.estate}</div>
            <div className="filler" />
          </div>
        ) : null}
        <div className="destinations-hero-title">{featuredData.title}</div>
        {detail ? (
          <>
            <div className="destinations-hero-description detail">
              <span className="hero-article-date">{featuredData.updated}</span>
              <div className="destinations-hero-discover detail" onClick={() => navigate('/destinations')}>
                <div>
                  <i className="la la-angle-left" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="destinations-hero-description">
              <p>{featuredData.description}</p>
              <span className="hero-article-date">{featuredData.updated}</span>
            </div>
            <Link to={path ? path : ''}>
              <div className="destinations-hero-discover">
                <p>DISCOVER</p>
                <DiscoverArrow />
              </div>
            </Link>
          </>
        )}
      </div>
    </>
  )
}

export default Hero
